.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0.7s linear alternate-reverse;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #282c34;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  0%   {transform: rotate(0deg)}
  25%  {transform: rotate(-0.1deg)}
  50%  {transform: rotate(0deg)}
  100% {transform: rotate(0.1deg)}
}
